.coupons-list {
  width: 60%;
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 220px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin-bottom: 50px;
}
.coupon-offer {
  width: 15%;
  border-right: 3px solid gray;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coupon-offer > b {
  font-size: 35px;
  height: 94px !important;
  width: 72px !important;
}
.coupon-description {
  width: 85%;
  height: 100%;
  padding: 10px 15px;
}
.coupon-description > p {
  font-size: 20px;
  margin: auto;
  text-align: left;
  font-weight: 600;
  height: 60px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.info-icon {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 3px solid gray;
}
.coupon-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32%;
  margin-top: 10px;
}
.coupon-valida-till {
  margin: 10px 0;
  width: 85%;
}
.coupon-valida-till > p {
  margin: 0;
  color: gray;
  padding: 0;
  font-size: 17px;
}
.info-icon svg.iconify.iconify--mdi {
  margin-right: 2px;
  font-size: 33px;
}
.apply-buttons {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  font-size: 23px;
}
.coupon-code {
  width: 60%;
  height: 55%;
  border-radius: 10px;
  background-color: rgb(38, 228, 136, 0.4);
  border: 1px dashed rgb(38, 228, 136);
}
.coupon-apply {
  width: 35%;
  height: 55%;
  border-radius: 30px;
  color: white;
  background-color: #20a8d8;
  border: none;
}
