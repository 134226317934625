.signInBox{
    height: auto;
    width: 45vw;
    display: flex;
    padding-bottom: 40px;
    border-radius: 10px;
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
    flex-direction: column;
}
.signInBox span{
    color: orange;
    text-decoration: underline;
    cursor: pointer;
}
@media (min-width: 768px) and (max-width: 920px) {
    .signInBox {
      width: 65vw;}
img.iconwidth{
    position: absolute;
    top: 70px !important;
    left: 10px;
    width: 220px !important;
}
    }
@media (min-width: 320px) and (max-width: 767px) {
    .signInBox {
      width: 98vw;}
img.iconwidth{
    position: absolute;
    top: 70px !important;
    left: 10px;
    width: 120px !important;
}
    }