#input_design {
  padding: 0.85rem 0.8rem;
  border: 1px solid #007dc6;
  font-size: 0.9rem;
  height: 50px;
}

#option_design {
  padding: 0.6rem 0.8rem;
  border: 1px solid #007dc6;
  font-size: 0.9rem;
  color: #007dc6;
  height: 50px;
}
.appointment-payment-confirmation .MuiPaper-root,
.appointment-payment-confirmation-1 .MuiPaper-root {
  border-radius: 20px !important;
}
.appointment-payment-confirmation .MuiDialogContent-root {
  margin-top: 20px !important;
}
.cross-icon-confirm {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.appointment-payment-confirmation .MuiDialogActions-root {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  margin-bottom: 10px;
}
.pay-later-btn,
.pay-now-btn {
  padding: 8px 32px !important;
  background-color: #007dc6 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  font-size: 17px !important;
}
.pay-later-btn {
  background-color: #e8e7e7 !important;
  color: #0d6efd !important;
}
#alert-dialog-description-paylater > p,
#alert-dialog-description > p {
  font-size: 17px;
  color: #035e93 !important;
}
#alert-dialog-description-paylater {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  gap: 20px !important;
}
#alert-dialog-description-paylater > b {
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #007dc6 !important;
}
.appointment-payment-confirmation .MuiDialogContent-root {
  padding-bottom: 0px !important;
}
