.nav-dropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: 1px solid;
}
.notification-title {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification-container {
  background-color: rgb(143, 139, 139);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.notification-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.show-sidebar {
  display: none;
}
.icons-pic{
       margin: -3px 0 0 3px;
}
.icons-pic-paymets{
  margin: -3px 0 0 0;
}



@media (min-width: 768px) and (max-width: 820px) {
  .show-sidebar {
    display: block;
  }
  .cross-button {
    position: absolute;
    top: 5%;
    right: 5%;
    position: fixed;
    z-index: 9999999;
    padding: 1px 10px;
    border-radius: 50%;
    font-size: 20px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .show-sidebar {
    display: block;
  }
  .cross-button {
    position: absolute;
    top: 1%;
    right:33%;
    position: fixed;
    z-index: 9999999;
    padding: 1px 10px;
    border-radius: 50%;
    font-size: 20px;
    background-color: transparent;
    border: none;
  }
}

