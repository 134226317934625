#time_pills{
    cursor: pointer;
    padding: .40rem 2rem;
    border: 1px solid #007DC6;
    
}

#time_pills:hover{
    background: #007DC6;
    color: rgb(234, 237, 236);
}