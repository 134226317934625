.notificationContainer {
  align-items: center;
  position: relative;
  cursor: pointer;
}
.notificationCount {
  margin: 0;
  padding: 0;
  position: absolute;
  top: -2px;
  left: 10px;
}
.bg-red {
  background-color: rgb(255, 153, 0);
  color: white;
  border-radius: 50%;
  width: 19px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  align-items: center;
}
.vertical-line{
  border-right: 2px solid #f16014;
  padding: 5px 20px 5px 0;
}
.downArrow {
  position: absolute;
  top: 70%;
  right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  z-index: 99;
}
