.loader-container {
  margin: 0;
  padding: 0;
  position: relative;
}
.cell-center {
  text-align: center;
  margin: auto;
}
.table-filter {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 20px 20px 0 10px;
  gap: 20px;
}
.search-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.table-page-select {
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #007dc6;
  height: 50px;
}
.table-status-container {
  padding: 6px 15px;
  border-radius: 5px;
  text-align: center;
  width: 120px;
  font-size: 12px;
}
.table td{font-size: 12px;}
.table-service {
  text-align: left;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table-download {
  padding: 6px 29px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007dc6;
  color: white;
}
.report-header {
  text-align: center;
  margin-top: 10px;
  font-family: "Gotham Black" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
  color: #007dc6;
}
@media (min-width: 768px) and (max-width: 820px) {
  .sidebar-view {
    margin: 0;
    padding: 0;
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .sidebar-view {
    margin: 0;
    padding: 0;
    display: none;
  }
}
