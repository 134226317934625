.heading {
  font-family: "Gotham Black";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
  text-transform: uppercase;
  color: #007dc6;
}

.para {
  font-size: large;
  color: orange;
  width:fit-content;
}
