.appoint-card-details {
  font-family: GothamBook;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}
.back-btn-text {
  font-size: 15px;
  padding: 0;
  margin: 0;
}
.btn-text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 6px 15px;
  border-radius: 25px;
  gap: 10px;
  margin-right: 20px;
  background-color: rgb(0, 125, 198);
  color: white;
  border-color: transparent;
}
.table-page-options {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.table-page-options > span {
  margin-right: 10px;
}
.reschedule-text-center {
  padding: 6px 15px;
  border-radius: 25px;
  gap: 10px;
  margin-right: 20px;
  background-color: rgb(0, 125, 198);
  color: white;
  border-color: transparent;
}

.next{
  background-color: #007dc6;
  border-color: transparent;
  color: #fff;
  margin-left: 5px;
  height: 35px;
  border-radius: 4px;}
.prev{
    background-color: #007dc6;
    border-color: transparent;
    color: #fff;
    margin-right: 5px;
    height: 35px;
    border-radius: 4px;
  }
  .leftarrow{border-radius: 4px; border-color: transparent; width: 40px; height: 35px;}
  .rightarrow{border-radius: 4px; border-color: transparent; width: 40px; height: 35px;}
  .searchtableinput{margin-bottom: 0px;}
  .table-download{border-color: transparent; }
  .table-download iconify {font-size: 24px;}
  .MuiTextField-root{width: 100% !important;}
  .table-filterleft{width: 50%;}
  .table-filterright{width: 50%; align-items: center; display: flex; justify-content: end;}
  .table-filterright .table-page-select{margin-right: 10px;}
  .table-page-optionsleft{width: 50%;}
  .table-page-optionsright{width: 50%; align-items: center; display: flex; justify-content: end;}
  @media (max-width: 450px) {
    .report-date > div{height: 40px;}
    .MuiTextField-root{width: 100% !important; margin-bottom: 20px !important;}
    .table-filter {display: inherit; padding: 20px 0px 0 0px;}
    .searchtableinput{height: 40px; margin-bottom: 20px;}
    .table-filterleft {width: 100%;}
    .table-filterright{width: 100%; justify-content: start;}
    .table-filterright .table-page-select{width: 72%; height: 40px;}
    .table-page-options{display: inherit;}
    .table-page-optionsleft {width: 100%;}
    .table-page-optionsright{width: 100%;}
  }
  