#profile {
  width: 4rem;
}
.date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#makeCall_btn {
  color: white;
  /* padding: .70rem 4rem; */
  font-size: 0.8rem;
  float: left;
  margin-left: -37px;
  padding: 0.4rem 2rem;
}

/* ApntmentChild1 */
/* 
#Consult_btn{
    border: 10px solid black;
} */

/* DoctorCard */

#doctor_card {
  border: none;
  box-shadow: -2px 9px 12px rgba(0 0 0 /0.2);
  border-radius: 0.8rem;
}

#appontment_btn {
  padding: 0.7rem 2rem;
}

#continue_btn {
  padding: 0.6rem 3.6rem;
  font-size: 1.2rem;
}

.consult_btn {
  padding: 0.7rem 6rem;
  border-radius: 2rem;
  background-color: orange;
  color: white;
  border: 1px solid orange;
  margin: 0 5px;
}

.tele_consult_btn {
  /* border-radius: 2rem; */
  margin-left: -46px;
  padding: 0.7rem 3rem 0.7rem 4rem;
  border: 1px solid orange;
  background: transparent;
  border-radius: 2rem;
}
.consult_btn_unselected {
  /* border-radius: 2rem; */
  /* margin-right: -46px; */
  padding: 0.7rem 6rem;
  border: 1px solid orange;
  background: transparent;
  border-radius: 2rem;
  margin: 0 5px;
}
.centerLoader2 {
  position: absolute;
  top: 43%;
  right: 38%;
  /* transform: translate(-50%, -50%); */
  position: fixed;
  z-index: 9999999;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .description.ms-3 h6 {
    /* font-size: 10px; */
    float: left;
    width: 100%;
  }
  .description.ms-3 p {
    font-size: 11px !important;
    margin-bottom: 2px;
  }
  .description.ms-3 {
    margin-left: 0.4rem !important;
  }
  .calltime p {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .calltime p span {
    font-size: 14px !important;
  }
  .weekday p.pe-4 {
    font-size: 11px !important;
    padding-right: 0px !important;
    float: right;
  }
  #doctor-profile {
    width: 2.5rem;
  }
  .consult_btn_unselected {
    padding: 0.7rem 5rem;
    border: 1px solid orange;
    background: transparent;
    border-radius: 2rem;
    margin: 10px 0px 0;
    width: 100%;
  }
  .consult_btn {
    padding: 0.7rem 5rem;
    width: 100%;
    margin: 10px 0 0;
  }
  .col-6.col-sm-7.d-flex.ipadview {
    display: grid !important;
  }
  .centerLoader2 {
    position: absolute;
    top: 43%;
    right: 26%;
    position: fixed;
  }
}
