.sidebar {
    /* width: 276px; */
    height: auto;
    background: white;
    color: #fff;
    transition: all 0.3s;
    border-radius: 20px;
    overflow: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    
  }
  .sidebar .nav-link.active {
    color: black;
    background: white;
  }
  .sidebar .nav-link {
    display: block;
    padding: 0rem 1rem;
    color: #333333;
    text-decoration: none;
    background: transparent;
    transition: 0.2s all;
    
/* identical to box height, or 16px */
  }
  .hr{
   border-bottom: 1px solid rgba(228, 241, 249, 1);
   padding: 1.3rem 0rem;
   margin: 0rem 1rem;
   color: #333333;
   cursor: pointer;
  }
  .hr span{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #333333;
    text-transform: capitalize;
    font-family: 'GothamBook';

  }
  
  .nav-link {
    color: #333333;
    font-size: 5px;
  }
  
  .sidebar .nav-link:hover,
  .sidebar .nav-link.active {
    color: #20a8d8;
  }
  
  .sidebar .nav-item-list {
    list-style-type: none;
    background-color: #262a2e;
    padding-left: 0;
  }
  