#Apntmnt_categ p{
    /* background: red; */

    font-size: 16px;
    border-radius: .90rem;
    /* color: #007DC6; */
}
#Apntmnt_categ {
    color: #007dc6da;
    background:url("../../../assets/mainIcon/icons/Group.png") 0px 0rem no-repeat #fff;
    background-size:80px;
    background-position: 86% 5px;
}
#Apntmnt_categ:hover{
    /* background-color: #007DC6; */
    cursor: pointer;
    color: white;
    background:url("../../../assets/mainIcon/icons/Group.png") 0px 0rem no-repeat #b3dcf3;
    background-size:80px;
    background-position: 86% 5px;
    
}
.cat-card{
   height: 190px !important;
}
@media screen and (max-width: 1060px) {
    .cat-card {
        height: 180px !important;
    }
  }

