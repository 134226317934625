.patient_header h4 {
  font-family: "Gotham-Black";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 30px;
  /* line-height: 33px; */
  text-transform: uppercase;

  /* 1 */

  color: #007dc6;
}
.--header h4 {
  font-family: "Gotham-Black";
  font-style: normal;
  font-size: 30px;

  color: #007dc6;
}

.patient_otp {
  font-family: "Gotham-Black";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
  text-transform: uppercase;
  color: #007dc6;
}
#maringo-color {
  color: #007dc6;
}
.errorText {
  color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
