.doctor-heading {
  color: #1a2352;
   font-family: 'GothamBook' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
hr{float: left;width: 100%;}
.doctortiming.justify-content-between.align-items-center.mb-4.doctortiming{float: left;width: 100%;}
.doctor-profile p {
  font-family: "GothamBook";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.doctortiming #timing {
  font-family: 'GothamBook' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
padding-right: 20px;
  /* dark-blue */float: left;
  color: #1a2352;
}
.doctortiming .text-color {
  font-family: "GothamBook";
  font-style: normal;
  font-weight: 500;float: left;
  font-size: 12px;
  line-height: 19px;
padding-right: 10px;
  color: #333333;
}
.doctor-profile-description h4 {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* dark-blue */
  color: #1a2352;
}
#profile-para {
  font-family: "GothamBook";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  /* text */

  color: #333333;
}
.language p,
li {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #000000;
}
