.payment-summary .payment-heading {
  font-family: "Gotham Black", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 30px;
  line-height: 33px;
  text-transform: uppercase;

  /* 1 */

  /* 1 */

  color: #007dc6;
}
