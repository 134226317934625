.privacy-policy {
  width: 100%;
  height: 128vh;
  position: relative;
}
.scroll-bar {
  padding: 1rem 0 0 1rem;
  height: auto;

}
