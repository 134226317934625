.coupon-container {
    width: 64.1%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    gap: 20px;
    background-color: rgb(226, 220, 220);
    border-radius: 10px;
    margin-left: 18px;
}
.coupon-container > input {
    width: 82%;
    height: 38px;
    border-radius: 10px;
    border: 1px solid rgb(128, 128, 128);
}
.coupon-container > input:focus{
    outline: none;
}
.coupon-container > button {
  padding: 6px 25px;
  border-radius: 10px;
  background-color: rgb(0, 125, 198);
  color: white;
  border: none;
}
